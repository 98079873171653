@import '../../../../scss/theme-bootstrap';

.countdown-timer {
  text-align: center;
  background-color: $color-belgian-linen;
  color: $color-off-black;
  height: 30px;
  line-height: get-line-height(14px, 30px);
  &__inner {
    font-size: 14px;
    letter-spacing: 0;
    font-family: $primary-font;
    font-weight: 500;
  }
  &__timer {
    font-weight: bold;
  }
  &--hide {
    display: none;
  }
}
